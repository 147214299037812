.v-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.v-dashboard-header {
  padding: 5px 0 15px 0;
}

.v-dashboard-title {
  font-size: 16px;
  font-weight: bold;
}

.v-dashboard-content {
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 15px;
  padding-bottom: 15px;
}

.v-dashboardLink {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.v-ewsGroup {
  display: flex;
  flex-direction: column;
  min-width: 60px;
}

.v-ewsGroup--blue,
.v-ewsGroup--red,
.v-ewsGroup--orange {
  flex: 1;
}

@media only screen and (min-width: 1700px) {
  .v-ewsGroup--yellow {
    flex: 2;
  }

  .v-ewsGroup--expanded {
    flex: 2;
  }
}

@media only screen and (max-width: 1699px) {
  .v-ewsGroup--yellow {
    flex: 1;
  }

  .v-ewsGroup--expanded {
    flex: 1;
  }
}

.v-ewsGroup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  padding-bottom: 5px;
}

.v-ewsGroup-title {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
}

.v-ewsGroup-icon {
  font-size: 18px;
}

.v-ewsGroup-content {
  flex: 1;
  border-radius: var(--radius-lg);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v-ewsGroup-content--blue {
  background: #3e7e9a;
}

.v-ewsGroup-content--red {
  background: #9a261c;
}

.v-ewsGroup-content--orange {
  background: #ac5e15;
}

.v-ewsGroup-content--yellow {
  background: #debc38;
}

.v-ewsGroup-content--white {
  background: var(--ews-white);
}
