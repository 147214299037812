.v-toolbarWrapper {
  padding-top: 15px;
}

.v-toolbar {
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  top: 15px;
}

.v-toolbar-button {
  cursor: pointer;
  font-size: small;
  font-weight: bold;
  width: 100%;
  border-radius: 5px;
  padding: 10px 0;
  margin-bottom: 10px;
  text-align: center;
  background-color: var(--toolbar-button-color);

  &.is-active {
    background-color: var(--button-active-color);
  }
}

.v-toolbar-button--icon {
  padding: 7px 0;
}
