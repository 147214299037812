.v-sidebar {
  position: fixed;
  top: 0;
  width: 175px;
  height: 100%;
  border-radius: 0px var(--radius-lg) var(--radius-lg) 0px;
  background-color: var(--sidebar-bg-color);
}

.v-sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.v-sidebar a {
  text-decoration: none;
}

.v-sidebar-logo {
  margin: 22px 0px 60px 17px;
  display: flex;
  align-items: center;
  cursor: default;
}

.v-sidebar-footer {
  justify-self: flex-end;
}

.v-sidebar-icon {
  font-family: icons;
  font-size: x-large;
  margin-right: 5px;
}

.v-sidebar-item {
  color: var(--sidebar-ft-color);
  margin: 10px 0px 20px 25px;
  font-size: smaller;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  cursor: pointer;
}

.v-sidebar-item--bottom {
  align-self: flex-end;
}

@media only screen and (max-width: 1024px) {
  .v-sidebar {
    width: 60px;
  }

  .v-sidebar-logo {
    margin-left: 8px;
  }

  .v-sidebar-item {
    margin-left: 18px;
    // padding-right: 10px;
  }

  .v-sidebar-label {
    visibility: hidden;
  }

  .v-contentView {
    margin-left: 85px;
  }
}
