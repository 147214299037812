.v-patientPanel {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 5px;
}

.v-patientPanel-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: var(--pri-bg-color);
  // box-shadow: 0px 10px 20px 10px black;
}

.v-devicePanel {
  // overflow: scroll;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */

  padding-top: 20px;
}

.v-devicePanel::-webkit-scrollbar {
  display: none;
}

.v-deviceInfo {
}

.v-deviceInfo-title {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.v-deviceInfo-subtitle {
  font-size: 10px;
  padding-top: 2px;
}

.v-deviceIndicator {
  font-size: 8px;
  border-radius: 10px;
  padding: 1px 6px;
}

.v-deviceIndicator--connected {
  color: #26b216;
  border: solid 1px #26b216;
}

.v-deviceIndicator--disconnected {
  color: #ff1744;
  border: solid 1px #ff1744;
}

.v-metricTileContainer {
  display: flex;
  flex-wrap: wrap;
  // padding: 3px 5px;
  margin: 10px 0;
  column-gap: 8px;
  row-gap: 8px;
}

.v-metricTile {
  background-color: var(--chart-bg-color);
  display: flex;
  border-radius: 5px;
  width: 250px;
  justify-content: space-between;
}

.v-metricTile-display {
  display: flex;
  padding: 10px;
  align-content: center;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.v-metricTile-name {
  min-width: 40px;
  font-size: 10px;
  flex-wrap: wrap;
  flex: 1 1 0;
}

.v-metricTile-value {
  line-height: 41.5px;
  min-width: 70px;
  text-align: center;
  align-self: center;
  flex: 2 1 0;
}

.v-metricTile-unit {
  font-size: 10px;
  align-self: flex-end;
  flex: 1 1 0;
}

.v-metricTile-control {
  display: flex;
  flex-direction: column;
  border-left: solid 1px var(--pri-bg-color);
}

.v-metricTile-topButton {
  padding: 0 7px;
  flex: 1;
  cursor: pointer;
  border-bottom: solid 1px var(--pri-bg-color);
}

.v-metricTile-bottomButton {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.v-metricControl {
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 15px;
  height: 100%;
  padding-top: 2px;
}

.v-metricControl-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-metricControl-name {
  font-size: 11px;
  flex-wrap: nowrap;
  align-self: flex-end;
}

.v-metricControl-display {
  display: flex;
  padding: 5px 0 10px 0;
}

.v-metricControl-value {
  font-size: 35px;
  line-height: 30px;
  min-width: 50px;
}

.v-metricControl-unit {
  font-size: 10px;
}

.v-metricControl-button {
  cursor: pointer;
}

.v-metricDisplay {
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.v-metricDisplay-title {
  font-size: 14px;
  flex-wrap: nowrap;
  height: 30%;
}

.v-metricDisplay-content {
  display: flex;
  justify-content: center;
  column-gap: 8px;
}

.v-metricDisplay-value {
  font-size: 36px;
}

.v-metricDisplay-unit {
  font-size: 12px;
  align-self: flex-end;
}
