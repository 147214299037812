@import 'variables';

.v-header {
  width: 100%;
  display: flex;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid var(--border-color);
}

.v-header-item {
  margin: 0 40px 0 10px;
}

.v-header-item--bold {
  font-weight: bold;
}

.v-header-item--backButton {
  font-weight: bold;
  cursor: pointer;
  margin: 0 30px 0 0;

  &:before {
    position: relative;
    bottom: 2px;
    content: '';
    display: inline-block;
    /* By using an em scale, the arrows will size with the font */
    width: 0.4em;
    height: 0.4em;
    border-right: 1px solid $font-color-primary;
    border-top: 1px solid $font-color-primary;
    transform: rotate(-135deg);
    margin-right: 0.5em;
  }
}

.v-header-item--alignRight {
  margin-left: auto;
  margin-right: 5px;
}
