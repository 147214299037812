@import '../../index.css';
.v-iconButton {
  font-size: small;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.v-chartControl {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-chartControl-header {
  display: flex;
  justify-content: flex-end;
  border-bottom: solid 1px var(--pri-bg-color);
}

.v-vitalChart {
  margin: 10px 0;
}

.v-vitalChart-content {
  flex: 1;
  background-color: var(--chart-bg-color);
  border-top-left-radius: var(--radius-xs);
  border-bottom-left-radius: var(--radius-xs);
}

.v-timeAxis {
  display: flex;
  width: 100%;
}

.v-vitalChart-rightView {
  width: 170px;
  display: flex;
  flex-direction: column;
  border-left: solid 1px var(--pri-bg-color);
  background-color: var(--chart-bg-color);
  border-top-right-radius: var(--radius-xs);
  border-bottom-right-radius: var(--radius-xs);
}

.v-noteChart {
  padding-bottom: 3px;
}

.v-noteChart-content {
  flex: 1;
  border-right: solid 1px var(--pri-bg-color);
  background-color: var(--chart-bar-color);
  border-top-left-radius: var(--radius-xs);
  border-bottom-left-radius: var(--radius-xs);
}

.v-noteChart-rightView {
  width: 170px;
  display: flex;
  justify-content: center;
  background-color: var(--chart-bar-color);
  border-top-right-radius: var(--radius-xs);
  border-bottom-right-radius: var(--radius-xs);
}

.v-noteChart-button {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-gaugeChart {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.v-chartButton {
  cursor: pointer;
  padding: 3px 7px;
  border-left: solid 1px var(--pri-bg-color);
}

.v-chartButton--faded {
  opacity: 0.3;
}
.v-chartButton--faded .v-chartButton-text {
  color: var(--pri-ft-color);
}

.v-chartButton-text {
  color: #26b216;
  font-size: 14px;
}

.v-scoreChart {
  width: 100%;
}

.v-gaugeChart {
  align-self: center;
}

.v-chart-content {
  flex: 1;
}

.v-chart-control {
  display: flex;
  width: 170px;
  flex-direction: column;
  justify-content: center;
}

.v-chart-controlButton {
  height: 30px;
  border-radius: 3px;
  text-align: center;
  background-color: var(--chart-bar-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.v-dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.v-dashboard-header {
  padding: 5px 0 15px 0;
}

.v-dashboard-title {
  font-size: 16px;
  font-weight: bold;
}

.v-dashboard-content {
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 15px;
  padding-bottom: 15px;
}

.v-dashboardLink {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.v-ewsGroup {
  display: flex;
  flex-direction: column;
  min-width: 60px;
}

.v-ewsGroup--blue,
.v-ewsGroup--red,
.v-ewsGroup--orange {
  flex: 1;
}

@media only screen and (min-width: 1700px) {
  .v-ewsGroup--yellow {
    flex: 2;
  }
  .v-ewsGroup--expanded {
    flex: 2;
  }
}
@media only screen and (max-width: 1699px) {
  .v-ewsGroup--yellow {
    flex: 1;
  }
  .v-ewsGroup--expanded {
    flex: 1;
  }
}
.v-ewsGroup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  padding-bottom: 5px;
}

.v-ewsGroup-title {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
}

.v-ewsGroup-icon {
  font-size: 18px;
}

.v-ewsGroup-content {
  flex: 1;
  border-radius: var(--radius-lg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v-ewsGroup-content--blue {
  background: #3e7e9a;
}

.v-ewsGroup-content--red {
  background: #9a261c;
}

.v-ewsGroup-content--orange {
  background: #ac5e15;
}

.v-ewsGroup-content--yellow {
  background: #debc38;
}

.v-ewsGroup-content--white {
  background: var(--ews-white);
}

.v-dropdown-trigger {
  height: 100%;
}

.v-dropdown-trigger:hover .v-dropdown-container {
  visibility: visible;
  transition-delay: 0s;
}

.v-dropdown-wrapper {
  position: relative;
  top: 15px;
  left: 90px;
}
.v-dropdown-wrapper a:focus {
  text-decoration: none;
}

.v-dropdown-container {
  background-color: var(--chart-bar-color);
  min-width: 120px;
  z-index: 100;
  position: absolute;
  visibility: hidden;
  padding-left: 0px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 0px;
  right: -8px;
  transition: 0.1s 0.1s;
}

.v-dropdown-list {
  list-style-type: none;
  font-size: 14px;
  display: block;
  margin: 0;
}

.v-dropdown-list:hover {
  background-color: var(--button-hover-color);
  border-radius: 4px;
}

.v-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6em 1em;
  color: var(--pri-ft-color);
}

.v-header {
  width: 100%;
  display: flex;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid var(--border-color);
}

.v-header-item {
  margin: 0 40px 0 10px;
}

.v-header-item--bold {
  font-weight: bold;
}

.v-header-item--backButton {
  font-weight: bold;
  cursor: pointer;
  margin: 0 30px 0 0;
}
.v-header-item--backButton:before {
  position: relative;
  bottom: 2px;
  content: "";
  display: inline-block;
  /* By using an em scale, the arrows will size with the font */
  width: 0.4em;
  height: 0.4em;
  border-right: 1px solid var(--pri-ft-color);
  border-top: 1px solid var(--pri-ft-color);
  transform: rotate(-135deg);
  margin-right: 0.5em;
}

.v-header-item--alignRight {
  margin-left: auto;
  margin-right: 5px;
}

.highcharts-range-selector-group {
  display: none;
}

.highcharts-container {
  border-top-left-radius: var(--radius-xs);
  border-bottom-left-radius: var(--radius-xs);
}

.highcharts-loading {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.highcharts-loading-inner {
  border: 6px solid var(--pri-bg-color);
  border-top: 8px solid transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.v-layout {
  display: flex;
  width: 100%;
}

.v-layout--vertical {
  flex-direction: column;
}

.v-rootPage {
  display: flex;
  background-color: var(--pri-bg-color);
}

.v-topbar {
  margin: 15px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  width: 100%;
}

.v-loadingPage {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 50px;
  background-color: var(--pri-bg-color);
  color: var(--pri-ft-color);
}

.v-contentView {
  width: 100%;
  margin: 0 20px 0 200px;
  display: flex;
  flex-direction: column;
}

.v-contentView--largeMargin {
  margin: 60px 0px 20px 0px;
}

.v-contentArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-fixedWidthWrapper {
  width: 800px;
}

.v-contentTitle {
  color: var(--pri-ft-color);
  font-weight: bold;
  font-size: 21px;
}

.v-row {
  display: flex;
  justify-content: space-between;
}

.v-row--header {
  padding: 25px 0 4px 0;
  font-size: 15px;
  font-weight: bold;
}

.v-row--patient {
  background-color: var(--tile-color);
  border-radius: var(--radius-sm);
  padding: 14px 0;
  margin: 6px 0px;
  font-size: small;
  text-decoration: none;
  color: inherit;
}
.v-row--patient:hover {
  background-color: var(--patient-row-hover);
  color: black;
}

.v-row-item {
  flex-basis: 22.5%;
  align-self: center;
}

.v-row-item--wide {
  flex-basis: 45%;
}

.v-row-item--short {
  flex-basis: 15%;
}

.v-row-item--name {
  padding-left: 1em;
  padding-bottom: 0.5em;
  font-weight: bold;
}

.v-row-item--id {
  padding-left: 1em;
}

.v-row-item--data {
  font-weight: bold;
  font-size: large;
}

.v-row-item--unit {
  padding-left: 0.8em;
}

.v-panel {
  width: 100%;
}

.v-settingsPane {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.v-interventionPanel {
  margin-bottom: 20px;
}

.v-container-top {
  border: solid 1px var(--chart-bar-color);
  border-bottom: 0;
}

.v-container-bottom {
  border: solid 1px var(--chart-bar-color);
  border-top: 0;
  margin-bottom: 10px;
}

.v-surface-header {
  display: flex;
  justify-content: space-between;
}

.v-surface-name {
  padding-left: 5px;
  padding-top: 2px;
}

.v-flexContainer {
  margin: 20px 8px 20px calc(10px + 8px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;
}

.v-flexContainer--bottom {
  flex-wrap: wrap-reverse;
}

.v-flexContainer--col0 .v-patientCard,
.v-flexContainer--col0 .v-patientTile,
.v-flexContainer--col1 .v-patientCard,
.v-flexContainer--col1 .v-patientTile {
  flex: 1 1 100%;
  max-width: calc(100% - 10px);
}

.v-flexContainer--col2 .v-patientCard,
.v-flexContainer--col2 .v-patientTile {
  min-width: calc(50% - 10px);
  max-width: calc(50% - 10px);
}

.v-flexContainer--col3 .v-patientCard,
.v-flexContainer--col3 .v-patientTile {
  min-width: calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
}

.v-flexContainer--col4 .v-patientCard,
.v-flexContainer--col4 .v-patientTile {
  min-width: calc(25% - 10px);
  max-width: calc(25% - 10px);
}

.v-flexContainer--col5 .v-patientCard,
.v-flexContainer--col5 .v-patientTile {
  min-width: calc(20% - 10px);
  max-width: calc(20% - 10px);
}

.v-flexContainer--col6 .v-patientCard,
.v-flexContainer--col6 .v-patientTile {
  min-width: calc(16.66% - 10px);
  max-width: calc(16.66% - 10px);
}

.v-flexContainer--col7 .v-patientCard,
.v-flexContainer--col7 .v-patientTile {
  min-width: calc(14.28% - 10px);
  max-width: calc(14.28% - 10px);
}

.v-flexContainer--col8 .v-patientCard,
.v-flexContainer--col8 .v-patientTile {
  min-width: calc(12.5% - 10px);
  max-width: calc(12.5% - 10px);
}

.v-patientCard,
.v-patientTile {
  flex: 1;
  background: white;
  box-sizing: border-box;
  border-radius: var(--radius-sm);
  color: black;
  display: flex;
  flex-direction: column;
}

.v-patientCard:hover .v-hoverButton,
.v-patientTile:hover .v-hoverButton {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-hoverButton {
  display: flex;
  opacity: 0;
  transition-delay: 0.3s;
  font-size: 16px;
  padding: 4px;
  width: 16px;
  height: 16px;
  background-color: white;
  border: solid 1px #eee;
  border-radius: 20px;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 10;
  align-self: flex-end;
  margin-top: -8px;
  margin-right: -10px;
  margin-bottom: -18px;
}

.v-paddedWrapper {
  padding: 10px;
}

.v-patientCard-header {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.v-patientCard-id {
  font-size: 12px;
  padding-right: 10px;
  word-break: break-all;
}

.v-patientCard-label {
  font-size: 17px;
  font-weight: bold;
  padding-right: 10px;
  word-spacing: 30000px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.v-patientCard-score,
.v-patientTile-score {
  background-color: #f2f2f2;
  border-radius: var(--radius-xs);
  padding: 4px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.v-patientCard-score {
  min-width: 2em;
}

.v-patientTile-scoreTitle {
  font-size: x-small;
}

.v-patientCard-scoreValue,
.v-patientTile-scoreValue {
  font-weight: bold;
}

.v-patientCard-content {
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px #eee;
  display: flex;
  justify-content: space-between;
}

.v-patientTile-label {
  font-size: 14px;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex: 1;
}

.v-patientTile-content {
  column-gap: 5px;
  overflow-x: hidden;
  display: flex;
}

.v-patientCard-header:hover,
.v-patientTile-content:hover {
  overflow-x: auto;
}

.v-patientCard-header::-webkit-scrollbar,
.v-patientTile-content::-webkit-scrollbar {
  height: 8px;
}

.v-patientCard-header::-webkit-scrollbar-thumb,
.v-patientTile-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.v-patientTile-stats {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.v-dataCell {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-dataCell-header {
  font-size: x-small;
  display: flex;
  justify-content: space-between;
}

.v-dataCell-value {
  font-weight: bold;
}

@media only screen and (max-width: 1880px) {
  .v-patientCard .v-dataCell-value {
    font-size: 20px;
  }
}
.v-patientPanel {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 5px;
}

.v-patientPanel-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: var(--pri-bg-color);
}

.v-devicePanel {
  padding-top: 20px;
}

.v-devicePanel::-webkit-scrollbar {
  display: none;
}

.v-deviceInfo-title {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.v-deviceInfo-subtitle {
  font-size: 10px;
  padding-top: 2px;
}

.v-deviceIndicator {
  font-size: 8px;
  border-radius: 10px;
  padding: 1px 6px;
}

.v-deviceIndicator--connected {
  color: #26b216;
  border: solid 1px #26b216;
}

.v-deviceIndicator--disconnected {
  color: #ff1744;
  border: solid 1px #ff1744;
}

.v-metricTileContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  column-gap: 8px;
  row-gap: 8px;
}

.v-metricTile {
  background-color: var(--chart-bg-color);
  display: flex;
  border-radius: 5px;
  width: 250px;
  justify-content: space-between;
}

.v-metricTile-display {
  display: flex;
  padding: 10px;
  align-content: center;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.v-metricTile-name {
  min-width: 40px;
  font-size: 10px;
  flex-wrap: wrap;
  flex: 1 1 0;
}

.v-metricTile-value {
  line-height: 41.5px;
  min-width: 70px;
  text-align: center;
  align-self: center;
  flex: 2 1 0;
}

.v-metricTile-unit {
  font-size: 10px;
  align-self: flex-end;
  flex: 1 1 0;
}

.v-metricTile-control {
  display: flex;
  flex-direction: column;
  border-left: solid 1px var(--pri-bg-color);
}

.v-metricTile-topButton {
  padding: 0 7px;
  flex: 1;
  cursor: pointer;
  border-bottom: solid 1px var(--pri-bg-color);
}

.v-metricTile-bottomButton {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.v-metricControl {
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 15px;
  height: 100%;
  padding-top: 2px;
}

.v-metricControl-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-metricControl-name {
  font-size: 11px;
  flex-wrap: nowrap;
  align-self: flex-end;
}

.v-metricControl-display {
  display: flex;
  padding: 5px 0 10px 0;
}

.v-metricControl-value {
  font-size: 35px;
  line-height: 30px;
  min-width: 50px;
}

.v-metricControl-unit {
  font-size: 10px;
}

.v-metricControl-button {
  cursor: pointer;
}

.v-metricDisplay {
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.v-metricDisplay-title {
  font-size: 14px;
  flex-wrap: nowrap;
  height: 30%;
}

.v-metricDisplay-content {
  display: flex;
  justify-content: center;
  column-gap: 8px;
}

.v-metricDisplay-value {
  font-size: 36px;
}

.v-metricDisplay-unit {
  font-size: 12px;
  align-self: flex-end;
}

.v-modal-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: -20px;
}

.v-modal-content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  min-width: 450px;
}

.v-modal-footer {
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 10px;
}

.v-modal-datepicker {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
}

.v-modal-warning {
  padding: 10px 20px;
  background: #fae8e8;
  border: solid 1px red;
  border-radius: 10px;
  margin: 0 20px 10px 20px;
}

.v-modal-closeButton {
  font-size: 40px !important;
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
  line-height: 1;
}

.v-modal-buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.v-iconButton-download {
  background: #f2f2f2;
}

.v-iconButton-download:hover {
  background: #e0e0e0 !important;
}

.v-navigator {
  display: flex;
  width: 100%;
  margin: 10px 0;
}

.v-navigator-content {
  flex: 1;
  background-color: var(--chart-bar-color);
  border: solid 1px var(--chart-bar-color);
  border: solid 1px var(--chart-bar-color);
  border-top-left-radius: var(--radius-xs);
  border-bottom-left-radius: var(--radius-xs);
}

.v-navigator-rightView {
  width: 170px;
  background-color: var(--chart-bar-color);
  border-top-right-radius: var(--radius-xs);
  border-bottom-right-radius: var(--radius-xs);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.v-navigator-leftView {
  background-color: transparent;
  padding-right: 1px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: 44px;
  left: 48px;
}

.v-navigator-label {
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-navigator-button {
  cursor: pointer;
}

.v-navigator-button--right {
  margin-left: 2px;
}

.v-navigator-button--disabled {
  cursor: default;
  opacity: 0.2;
}

.v-timeRangeDisplay {
  display: flex;
  align-items: center;
}

.v-timeRangeDisplay-text {
  font-size: 14px;
}

.v-sidebar {
  position: fixed;
  top: 0;
  width: 175px;
  height: 100%;
  border-radius: 0px var(--radius-lg) var(--radius-lg) 0px;
  background-color: var(--sidebar-bg-color);
}

.v-sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.v-sidebar a {
  text-decoration: none;
}

.v-sidebar-logo {
  margin: 22px 0px 60px 17px;
  display: flex;
  align-items: center;
  cursor: default;
}

.v-sidebar-footer {
  justify-self: flex-end;
}

.v-sidebar-icon {
  font-family: icons;
  font-size: x-large;
  margin-right: 5px;
}

.v-sidebar-item {
  color: var(--sidebar-ft-color);
  margin: 10px 0px 20px 25px;
  font-size: smaller;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  cursor: pointer;
}

.v-sidebar-item--bottom {
  align-self: flex-end;
}

@media only screen and (max-width: 1024px) {
  .v-sidebar {
    width: 60px;
  }
  .v-sidebar-logo {
    margin-left: 8px;
  }
  .v-sidebar-item {
    margin-left: 18px;
  }
  .v-sidebar-label {
    visibility: hidden;
  }
  .v-contentView {
    margin-left: 85px;
  }
}
.v-spinner {
  border: 4px solid #ccc;
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

.v-spinner--small {
  border: 4px solid #ccc;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.v-vitalSurface {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 20px;
}

.v-surface-title {
  font-weight: bold;
  padding: 10px 0;
  border-bottom: solid 1px var(--border-color);
}

.v-noteSurface-title {
  font-weight: bold;
  padding: 10px 0;
}

.v-table {
  margin: 20px;
}
.v-table th {
  text-align: left;
  padding-bottom: 15px;
}

.v-table-row {
  margin: 10px;
}

.v-table-icon {
  padding-right: 20px;
}

.v-table-cell {
  padding: 10px 0 10px 0;
}

.v-table-link {
  cursor: pointer;
}

.v-bar {
  margin: 2px;
  padding: 0 3px;
  background-color: rgb(49, 211, 28);
}

.v-bar--red {
  background-color: red;
}

.v-toolbarWrapper {
  padding-top: 15px;
}

.v-toolbar {
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  top: 15px;
}

.v-toolbar-button {
  cursor: pointer;
  font-size: small;
  font-weight: bold;
  width: 100%;
  border-radius: 5px;
  padding: 10px 0;
  margin-bottom: 10px;
  text-align: center;
  background-color: var(--toolbar-button-color);
}
.v-toolbar-button.is-active {
  background-color: var(--button-active-color);
}

.v-toolbar-button--icon {
  padding: 7px 0;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:after {
  content: attr(data-tooltip);
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 100%; /* put it on the top */
  background-color: var(--tooltip-color);
  padding: 5px;
  border-radius: 5px;
  width: max-content;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
}

[data-tooltip]:hover:after {
  opacity: 1;
}

.v-actionLink {
  color: #59b4dc;
  font-weight: bold;
  font-size: smaller;
  cursor: pointer;
  padding: 8px;
  margin: 0 15px 0 8px;
}

.v-actionLink--plain {
  color: var(--pri-ft-color);
}

.v-wardSelector {
  width: 10em;
  overflow-wrap: break-word;
}

.v-searchInput {
  border-radius: 15px;
  border-width: 0;
  padding: 7px 14px;
}
.v-searchInput:focus-visible {
  outline: none;
}

.v-collapsible {
  -webkit-transition: max-height 2s;
  -moz-transition: max-height 2s;
  -ms-transition: max-height 2s;
  -o-transition: max-height 2s;
  transition: max-height 2s;
}

.v-is-expanded {
  max-height: 2000px;
}

.v-is-collapsed {
  max-height: 0px;
}

.u-faded-opacity {
  opacity: 0.3;
}

.u-noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}

.u-pointer {
  cursor: pointer;
}

.u-hidden {
  visibility: hidden;
}

.u-fadeIn {
  animation: fadeIn 3s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

