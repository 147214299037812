.v-actionLink {
  color: #59b4dc;
  font-weight: bold;
  font-size: smaller;
  cursor: pointer;
  padding: 8px;
  margin: 0 15px 0 8px;
}

.v-actionLink--plain {
  color: var(--pri-ft-color);
}

.v-wardSelector {
  width: 10em;
  overflow-wrap: break-word;
}

.v-searchInput {
  border-radius: 15px;
  border-width: 0;
  padding: 7px 14px;

  &:focus-visible {
    outline: none;
  }
}
