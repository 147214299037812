[data-tooltip] {
  position: relative;
}

[data-tooltip]:after {
  content: attr(data-tooltip);
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 100%; /* put it on the top */
  background-color: var(--tooltip-color);
  padding: 5px;
  border-radius: 5px;
  width: max-content;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
}

[data-tooltip]:hover:after {
  opacity: 1;
}
