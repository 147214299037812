body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: icons;
  /* webpackIgnore: true */
  /* src: url('/VitalDetectIcon.ttf'); */
  src: url('../public/FPDS-Icon-Medium.ttf');
}

:root {
  --sidebar-bg-color: #27293e;
  --sidebar-ft-color: white;
  --radius-lg: 20px;
  --radius-sm: 10px;
  --radius-xs: 5px;
  --pri-ft-color: black;
  --pri-bg-color: #f4f4f4;
  --sec-ft-color: black;
  --sec-bg-color: white;
  --border-color: #707070;
}

html,
body,
#root {
  height: 100%;
}

.fontIcon {
  font-family: icons;
  font-size: 20px;
}

.fontIcon-large {
  font-family: icons;
  font-size: 30px;
}

.fontIcon-medium {
  font-family: icons;
  font-size: 17px;
}

.fontIcon-small {
  font-family: icons;
  font-size: 14px;
}

.fontIcon-xs {
  font-family: icons;
  font-size: 12px;
}

.flex {
  display: flex;
  flex-flow: nowrap;
}

.center {
  align-items: center;
}

.fillWidth {
  margin-right: auto;
}

.fillHeight {
  margin-bottom: auto;
}

/* 
.patientRow {
  background-color: var(--sec-bg-color);
  border-radius: var(--radius-sm);
  padding: 14px 0px;
  margin: 6px 0px;
  font-size: small;
}

.patientRow .name {
  font-weight: bold;
  padding-left: 20px;
}

.patientRow .data {
  font-weight: bold;
  font-size: large;
}

.patientRow .unit {
  padding-left: 9px;
  opacity: 50%;
} */

#app {
  min-height: 100%;
  background-color: var(--pri-bg-color);
  color: var(--pri-ft-color);
}

/* 
#app > div:last-child {
  flex: 1 0;
} */

#logo {
  margin: 22px 0px 0px 17px;
  display: flex;
  align-items: center;
  cursor: default;
}

/* #patientList {
  width: 800px;
}

#patientListTitle {
  margin: 40px 0px 20px 0px;
  color: var(--pri-ft-color);
} */

#title {
  font-weight: bold;
  font-size: large;
}

#refine {
  font-size: small;
  cursor: pointer;
  vertical-align: middle;
}

#refine > .fontIcon {
  margin-left: 3px;
}

#patientSheet {
  width: 100%;
}

#patientSheet > div {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  width: 100%;
  color: var(--pri-ft-color);
}

#patientSheet > div > div:nth-child(1) {
  margin-right: auto;
}

#patientSheet > div > div:nth-child(n + 2) {
  width: 20%;
}

#patientSheet a {
  color: inherit;
  text-decoration: inherit;
}

#patient > .seleted {
  background-color: #00bbff;
}

#patientSheetHeader {
  color: var(--pri-ft-color);
  font-weight: bold;
  font-size: small;
  padding-bottom: 10px;
}
