.v-dropdown-trigger {
  height: 100%;
}

.v-dropdown-trigger:hover .v-dropdown-container {
  visibility: visible;
  transition-delay: 0s;
}

.v-dropdown-wrapper {
  position: relative;
  top: 15px;
  left: 90px;
  a:focus {
    text-decoration: none;
  }
}

.v-dropdown-container {
  background-color: var(--chart-bar-color);
  min-width: 120px;
  z-index: 100;
  position: absolute;
  visibility: hidden;
  padding-left: 0px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 0px;
  right: -8px;
  transition: 0.1s 0.1s;
}

// .v-dropdown-container:after {
//   content: ' ';
//   border-bottom: 10px solid white;
//   border-left: 10px solid transparent;
//   border-right: 10px solid transparent;
//   position: absolute;
//   top: -10px;
//   right: 14px;
// }

// .v-dropdown-container .v-dropdown-list:not(:last-child) {
//   border-bottom: solid 1px gray;
// }

.v-dropdown-list {
  list-style-type: none;
  font-size: 14px;
  display: block;
  margin: 0;
}

.v-dropdown-list:hover {
  background-color: var(--button-hover-color);
  border-radius: 4px;
}

.v-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6em 1em;
  color: var(--pri-ft-color);
}
