.v-vitalSurface {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 20px;
}

.v-surface-title {
  font-weight: bold;
  padding: 10px 0;
  border-bottom: solid 1px var(--border-color);
}

.v-noteSurface-title {
  font-weight: bold;
  padding: 10px 0;
}
