.v-modal-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: -20px;
}

.v-modal-content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  min-width: 450px;
}

.v-modal-footer {
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 10px;
}

.v-modal-datepicker {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
}

.v-modal-warning {
  padding: 10px 20px;
  background: #fae8e8;
  border: solid 1px red;
  border-radius: 10px;
  margin: 0 20px 10px 20px;
}

.v-modal-closeButton {
  font-size: 40px !important;
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
  line-height: 1;
}

.v-modal-buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.v-iconButton-download {
  background: #f2f2f2;
}

.v-iconButton-download:hover {
  background: #e0e0e0 !important;
}
