.v-navigator {
  display: flex;
  width: 100%;
  margin: 10px 0;
}

.v-navigator-content {
  flex: 1;
  background-color: var(--chart-bar-color);
  border: solid 1px var(--chart-bar-color);
  border: solid 1px var(--chart-bar-color);
  border-top-left-radius: var(--radius-xs);
  border-bottom-left-radius: var(--radius-xs);
}

.v-navigator-rightView {
  width: $controlWidth;
  background-color: var(--chart-bar-color);
  border-top-right-radius: var(--radius-xs);
  border-bottom-right-radius: var(--radius-xs);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.v-navigator-leftView {
  background-color: transparent;
  padding-right: 1px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: 44px;
  left: 48px;
}

.v-navigator-label {
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-navigator-button {
  cursor: pointer;
}

.v-navigator-button--right {
  margin-left: 2px;
}

.v-navigator-button--disabled {
  cursor: default;
  opacity: 0.2;
}

.v-timeRangeDisplay {
  display: flex;
  align-items: center;
}

.v-timeRangeDisplay-text {
  font-size: 14px;
}
