@import 'variables';

.v-chartControl {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-chartControl-header {
  display: flex;
  justify-content: flex-end;
  border-bottom: solid 1px var(--pri-bg-color);
}

.v-vitalChart {
  margin: 10px 0;
}

.v-vitalChart-content {
  flex: 1;
  background-color: var(--chart-bg-color);
  border-top-left-radius: var(--radius-xs);
  border-bottom-left-radius: var(--radius-xs);
}

.v-timeAxis {
  display: flex;
  width: 100%;
}

.v-vitalChart-rightView {
  width: $controlWidth;
  display: flex;
  flex-direction: column;
  border-left: solid 1px var(--pri-bg-color);
  background-color: var(--chart-bg-color);
  border-top-right-radius: var(--radius-xs);
  border-bottom-right-radius: var(--radius-xs);
}

.v-noteChart {
  padding-bottom: 3px;
}

.v-noteChart-content {
  flex: 1;
  border-right: solid 1px var(--pri-bg-color);
  background-color: var(--chart-bar-color);
  border-top-left-radius: var(--radius-xs);
  border-bottom-left-radius: var(--radius-xs);
}

.v-noteChart-rightView {
  width: $controlWidth;
  display: flex;
  justify-content: center;
  background-color: var(--chart-bar-color);
  border-top-right-radius: var(--radius-xs);
  border-bottom-right-radius: var(--radius-xs);
}

.v-noteChart-button {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-gaugeChart {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.v-chartButton {
  cursor: pointer;
  padding: 3px 7px;
  border-left: solid 1px var(--pri-bg-color);
}

.v-chartButton--faded {
  opacity: 0.3;

  .v-chartButton-text {
    color: var(--pri-ft-color);
  }
}

.v-chartButton-text {
  color: #26b216;
  font-size: 14px;
}

.v-scoreChart {
  width: 100%;
}

.v-gaugeChart {
  align-self: center;
}

.v-chart-content {
  flex: 1;
}

.v-chart-control {
  display: flex;
  width: $controlWidth;
  flex-direction: column;
  justify-content: center;
}

.v-chart-controlButton {
  height: $rangeChart-bar-height;
  border-radius: 3px;
  text-align: center;
  background-color: var(--chart-bar-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
