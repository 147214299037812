.highcharts-range-selector-group {
  display: none;
}

.highcharts-container {
  border-top-left-radius: var(--radius-xs);
  border-bottom-left-radius: var(--radius-xs);
}

.highcharts-loading {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.highcharts-loading-inner {
  border: 6px solid var(--pri-bg-color);
  border-top: 8px solid transparent;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
