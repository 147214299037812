.v-rootPage {
  display: flex;
  background-color: var(--pri-bg-color);
}

.v-topbar {
  margin: 15px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  width: 100%;
}

.v-loadingPage {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 50px;
  background-color: var(--pri-bg-color);
  color: var(--pri-ft-color);
}

.v-contentView {
  width: 100%;
  margin: 0 20px 0 200px;
  display: flex;
  flex-direction: column;
}

.v-contentView--largeMargin {
  margin: 60px 0px 20px 0px;
}

.v-contentArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-fixedWidthWrapper {
  width: 800px;
}

.v-contentTitle {
  color: var(--pri-ft-color);
  font-weight: bold;
  font-size: 21px;
}

.v-row {
  display: flex;
  justify-content: space-between;
}

.v-row--header {
  padding: 25px 0 4px 0;
  font-size: 15px;
  font-weight: bold;
}

.v-row--patient {
  background-color: var(--tile-color);
  border-radius: var(--radius-sm);
  padding: 14px 0;
  margin: 6px 0px;
  font-size: small;
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: var(--patient-row-hover);
    color: black;
  }
}

.v-row-item {
  flex-basis: 22.5%;
  align-self: center;
}

.v-row-item--wide {
  flex-basis: 45%;
}

.v-row-item--short {
  flex-basis: 15%;
}

.v-row-item--name {
  padding-left: 1em;
  padding-bottom: 0.5em;
  font-weight: bold;
}

.v-row-item--id {
  padding-left: 1em;
}

.v-row-item--data {
  font-weight: bold;
  font-size: large;
}

.v-row-item--unit {
  padding-left: 0.8em;
}
