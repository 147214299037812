.v-flexContainer {
  margin: 20px 8px 20px calc(#{$column-gap} + 8px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: $column-gap;
}

.v-flexContainer--bottom {
  flex-wrap: wrap-reverse;
  // margin: 20px calc(#{$column-gap} + 8px) 20px 8px;
  // justify-content: flex-end;
}

.v-flexContainer--col0,
.v-flexContainer--col1 {
  .v-patientCard,
  .v-patientTile {
    flex: 1 1 100%;
    max-width: calc(100% - #{$column-gap});
  }
}

.v-flexContainer--col2 {
  .v-patientCard,
  .v-patientTile {
    min-width: calc(50% - #{$column-gap});
    max-width: calc(50% - #{$column-gap});
  }
}

.v-flexContainer--col3 {
  .v-patientCard,
  .v-patientTile {
    min-width: calc(33.33% - #{$column-gap});
    max-width: calc(33.33% - #{$column-gap});
  }
}

.v-flexContainer--col4 {
  .v-patientCard,
  .v-patientTile {
    min-width: calc(25% - #{$column-gap});
    max-width: calc(25% - #{$column-gap});
  }
}

.v-flexContainer--col5 {
  .v-patientCard,
  .v-patientTile {
    min-width: calc(20% - #{$column-gap});
    max-width: calc(20% - #{$column-gap});
  }
}

.v-flexContainer--col6 {
  .v-patientCard,
  .v-patientTile {
    min-width: calc(16.66% - #{$column-gap});
    max-width: calc(16.66% - #{$column-gap});
  }
}

.v-flexContainer--col7 {
  .v-patientCard,
  .v-patientTile {
    min-width: calc(14.28% - #{$column-gap});
    max-width: calc(14.28% - #{$column-gap});
  }
}

.v-flexContainer--col8 {
  .v-patientCard,
  .v-patientTile {
    min-width: calc(12.5% - #{$column-gap});
    max-width: calc(12.5% - #{$column-gap});
  }
}

.v-patientCard,
.v-patientTile {
  // flex: 1 1 calc(50% - #{$column-gap});
  flex: 1;
  background: white;
  box-sizing: border-box;
  border-radius: var(--radius-sm);
  color: black;
  display: flex;
  flex-direction: column;
}

.v-patientCard:hover,
.v-patientTile:hover {
  .v-hoverButton {
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.v-hoverButton {
  display: flex;
  opacity: 0;
  transition-delay: 0.3s;
  font-size: 16px;
  padding: 4px;
  width: 16px;
  height: 16px;
  background-color: white;
  border: solid 1px #eee;
  border-radius: 20px;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 10;
  align-self: flex-end;
  margin-top: -8px;
  margin-right: -10px;
  margin-bottom: -18px;
}

.v-paddedWrapper {
  padding: 10px;
}

.v-patientCard-header {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.v-patientCard-id {
  font-size: 12px;
  padding-right: 10px;
  word-break: break-all;
}

.v-patientCard-label {
  font-size: 17px;
  font-weight: bold;
  padding-right: 10px;
  word-spacing: 30000px; // force word break
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.v-patientCard-score,
.v-patientTile-score {
  background-color: #f2f2f2;
  border-radius: var(--radius-xs);
  padding: 4px;

  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.v-patientCard-score {
  min-width: 2em;
}

.v-patientTile-scoreTitle {
  font-size: x-small;
}

.v-patientCard-scoreValue,
.v-patientTile-scoreValue {
  font-weight: bold;
}

.v-patientCard-content {
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px #eee;

  display: flex;
  justify-content: space-between;
}

.v-patientTile-label {
  font-size: 14px;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex: 1;
}

.v-patientTile-content {
  column-gap: 5px;
  overflow-x: hidden;

  display: flex;
}

.v-patientCard-header:hover,
.v-patientTile-content:hover {
  overflow-x: auto;
}

.v-patientCard-header::-webkit-scrollbar,
.v-patientTile-content::-webkit-scrollbar {
  height: 8px;
}

.v-patientCard-header::-webkit-scrollbar-thumb,
.v-patientTile-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.v-patientTile-stats {
  flex: 1;

  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.v-dataCell {
  flex: 1 1 calc(50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v-dataCell-header {
  font-size: x-small;

  display: flex;
  justify-content: space-between;
}

.v-dataCell-value {
  font-weight: bold;
}

// @media only screen and (min-width: 1881px) {
// }
@media only screen and (max-width: 1880px) {
  .v-patientCard {
    .v-dataCell-value {
      font-size: 20px;
    }
  }
}
