.v-table {
  margin: 20px;
  th {
    text-align: left;
    padding-bottom: 15px;
  }
}
.v-table-row {
  margin: 10px;
}

.v-table-column {
}

.v-table-icon {
  padding-right: 20px;
}

.v-table-cell {
  padding: 10px 0 10px 0;
}

.v-table-link {
  cursor: pointer;
}

.v-bar {
  margin: 2px;
  padding: 0 3px;
  background-color: rgb(49, 211, 28);
}

.v-bar--red {
  background-color: red;
}
