.v-collapsible {
  -webkit-transition: max-height 2s;
  -moz-transition: max-height 2s;
  -ms-transition: max-height 2s;
  -o-transition: max-height 2s;
  transition: max-height 2s;
}

.v-is-expanded {
  max-height: 2000px;
}

.v-is-collapsed {
  max-height: 0px;
}

.u-faded-opacity {
  opacity: 0.3;
}

.u-noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}

.u-pointer {
  cursor: pointer;
}

.u-hidden {
  visibility: hidden;
}

.u-fadeIn {
  animation: fadeIn 3s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
