@import 'variables';

.v-panel {
  width: 100%;
}

.v-settingsPane {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}

.v-interventionPanel {
  margin-bottom: 20px;
}

.v-container-top {
  border: solid 1px var(--chart-bar-color);
  border-bottom: 0;
}

.v-container-bottom {
  border: solid 1px var(--chart-bar-color);
  border-top: 0;
  margin-bottom: 10px;
}

.v-surface-header {
  display: flex;
  justify-content: space-between;
}

.v-surface-name {
  padding-left: 5px;
  padding-top: 2px;
}

// .v-collapseButton {
//   cursor: pointer;

//   &:before {
//     position: relative;
//     right: 4px;
//     content: '';
//     display: inline-block;
//     /* By using an em scale, the arrows will size with the font */
//     width: 0.4em;
//     height: 0.4em;
//     border-right: 1px solid var(--pri-ft-color);
//     border-top: 1px solid var(--pri-ft-color);
//     transform: rotate(-45deg);
//     margin-right: 0.5em;
//   }
// }
